import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import './Login.css'

const ForgotPassword = ({ baseUrl }) => {

    const [credential, setCredentail] = useState({ email: "" })
    const [loader, setLoader] = useState(false)
    const [credentialValidity, setCredentailValidity] = useState(true)

    const navigate = useNavigate()
    const handleChange = (e) => {
        setCredentail({ ...credential, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {

        try {
            const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
            if (!emailRegex.test(credential.email)) {
                setCredentailValidity(false)
                return
            }
            if (!navigator.onLine) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are currently offline. Please check your internet connection and try again.',
                })
                return
            }
            setLoader(true)
            const response = await fetch(`${baseUrl}/authentication/reset-password`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: credential.email }) })
            const data = await response.json()
            if (data?.status) {
                Swal.fire({
                    title: 'Success',
                    text: 'Check your email for password',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    navigate('/login')
                });
                return
            } else {
                setCredentailValidity((false))
                return
            }
        }
        catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong on the server!',
            })
        }
        finally {
            setLoader(false)
        }
    }

    return (
        <div className='login-page'>
            {loader ? <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div> :
                <div className='container-fluid'>
                    <div className='row d-flex justify-content-center align-items-center '>
                        <div className='col-9 col-sm-8 col-md-6 col-lg-3 card d-flex flex-column justify-content-around login-page-card'>
                            <div className='login-page-ecowrap-logo'>
                                <img src='asset/ecowrap-logo.png' alt='Ecowrap logo' />
                            </div>
                            <div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInput"
                                        name='email'
                                        value={credential.email}
                                        placeholder="email"
                                        onChange={handleChange}
                                    />
                                    <label
                                        htmlFor="floatingInput">
                                        Email :
                                    </label>
                                </div>
                                {!credentialValidity && <div
                                    style={{ color: 'red', fontSize: '12px' }}>
                                    No Account with this Email
                                </div>}
                            </div>
                            <div
                                className='login-page-submit'>
                                <button
                                    className='btn btn-primary'
                                    onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default ForgotPassword