import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Login from './pages/Login'
import Home from './pages/Home'
import ForgotPassword from './pages/ForgotPassword';
function App() {
  const baseUrl = 'https://apis.ecowrap.in/b2g/eco-admin'
  // const baseUrl = 'http://localhost:3000/gov/admin'
  const authTokenData = localStorage.getItem('token')
  const [authToken, setAuthToken] = useState(authTokenData)

  return (
    <Router>
      <Routes>
        <Route exact path='/login' element={<Login baseUrl={baseUrl} setAuthToken={setAuthToken} />} />
        <Route exact path='/' element={<Home baseUrl={baseUrl} authToken={authToken} />} />
        <Route exact path='forgotPassword' element={<ForgotPassword baseUrl={baseUrl} />} />
      </Routes>
    </Router>
  );
}

export default App;
